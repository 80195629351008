<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="助通短信" name="1">
                    <el-form class="form-box" ref="ShortMsgFrom" v-bind:rules="rules" v-bind:model="ShortMsgFrom" label-width="110px">
                        <el-form-item label="key" prop="key">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="ShortMsgFrom.key" placeholder="请输入key"></el-input>
                        </el-form-item>
                        <el-form-item label="signature" prop="signature">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="ShortMsgFrom.signature" placeholder="请输入signature"></el-input>
                        </el-form-item>
                        <el-form-item label="username" prop="username">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="ShortMsgFrom.username" placeholder="请输入username"></el-input>
                        </el-form-item>
                        <el-form-item label="模板id" prop="tp_id">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="ShortMsgFrom.tp_id" placeholder="请输入模板id"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveShortMsgFrom">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>

                <el-tab-pane label="阿里大鱼短信" name="2">
                    <el-form class="form-box" ref="aliForm" v-bind:rules="rules" v-bind:model="aliForm" label-width="100px">
                        <!-- <el-form-item label="bucket" prop="bucket">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="aliForm.bucket"
                                placeholder="请输入bucket"></el-input>
                        </el-form-item>
                        <el-form-item label="internal" prop="internal">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="aliForm.internal"
                                placeholder="请输入internal"></el-input>
                        </el-form-item>
                        <el-form-item label="key" prop="key">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="aliForm.key"
                                placeholder="请输入key"></el-input>
                        </el-form-item>
                        <el-form-item label="ossurl" prop="ossurl">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="aliForm.ossurl"
                                placeholder="请输入ossurl"></el-input>
                        </el-form-item>
                        <el-form-item label="secret" prop="secret">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="aliForm.secret"
                                placeholder="请输入secret"></el-input>
                        </el-form-item>
                        <el-form-item label="url" prop="url">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="aliForm.url"
                                placeholder="请输入url"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveAliForm">提 交</el-button>
                        </el-form-item> -->
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
import loadEvents from '@/utils/loading'
let loads = new loadEvents();
export default {
    name: '',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            activeName: '1',
            menuList: ['设置', '助通短信'],
            aliForm: {},
            dingForm: {},
            qiyeForm: {},
            versionForm: {},
            header: {},
            AppForm: {},
            ShortMsgFrom: {},
            rules: {
                bucket: [
                    {
                        required: true,
                        message: 'bucket不能为空',
                        trigger: 'blur'
                    }
                ],
                appkey: [
                    {
                        required: true,
                        message: 'appkey 不能为空',
                        trigger: 'blur'
                    }
                ],
                mch_id: [
                    {
                        required: true,
                        message: 'mch_id不能为空',
                        trigger: 'blur'
                    }
                ],
                internal: [
                    {
                        required: true,
                        message: 'internal不能为空',
                        trigger: 'blur'
                    }
                ],
                key: [
                    {
                        required: true,
                        message: 'key不能为空',
                        trigger: 'blur'
                    }
                ],
                ossurl: [
                    {
                        required: true,
                        message: 'ossurl不能为空',
                        trigger: 'blur'
                    }
                ],
                url: [
                    {
                        required: true,
                        message: 'url不能为空',
                        trigger: 'blur'
                    }
                ],
                keyword: [
                    {
                        required: true,
                        message: 'keyword不能为空',
                        trigger: 'blur'
                    }
                ],
                appsecret: [
                    {
                        required: true,
                        message: 'appsecret不能为空',
                        trigger: 'blur'
                    }
                ],
                masterSecret: [
                    {
                        required: true,
                        message: 'masterSecret不能为空',
                        trigger: 'blur'
                    }
                ],
                persion: [
                    {
                        required: true,
                        message: 'persion不能为空',
                        trigger: 'blur'
                    }
                ],
                uid: [
                    {
                        required: true,
                        message: '审核id不能为空',
                        trigger: 'blur'
                    }
                ],
                token: [
                    {
                        required: true,
                        message: 'token不能为空',
                        trigger: 'blur'
                    }
                ],
                desc: [
                    {
                        required: true,
                        message: 'desc不能为空',
                        trigger: 'blur'
                    }
                ],
                examine: [
                    {
                        required: true,
                        message: '审核版本不能为空',
                        trigger: 'blur'
                    }
                ],
                v: [
                    {
                        required: true,
                        message: 'v不能为空',
                        trigger: 'blur'
                    }
                ],

                maximum: [{
                    required: true,
                    message: '额度不能为空',
                    trigger: 'blur'
                }, { message: '请输入数字值', pattern: /^[0-9]*[1-9][0-9]*$/ }],
                minimum: [{
                    required: true,
                    message: '不能为空',
                    trigger: 'blur'
                }, { message: '请输入数字值', pattern: /^[0-9]*[1-9][0-9]*$/ }],
                appid: [
                    {
                        required: true,
                        message: 'appid不能为空',
                        trigger: 'blur'
                    }
                ],
                // uri: [
                //     { validator: deal.checkFile, trigger: ['blur', 'change'] }
                // ],
                secret: [
                    {
                        required: true,
                        message: '版本不能为空',
                        trigger: 'blur'
                    }
                ],
                close: [
                    {
                        required: true,
                        message: '充值配置不能为空',
                        trigger: 'blur'
                    }
                ],
                customer: [
                    {
                        required: true,
                        message: 'customer不能为空',
                        trigger: 'blur'
                    }
                ],
                logisticssalt: [
                    {
                        required: true,
                        message: 'logisticssalt不能为空',
                        trigger: 'blur'
                    }
                ],
                bkkey: [
                    {
                        required: true,
                        message: 'bkkey不能为空',
                        trigger: 'blur'
                    }
                ],
                merchon: [
                    {
                        required: true,
                        message: 'merchon不能为空',
                        trigger: 'blur'
                    }
                ],
                ckkey: [
                    {
                        required: true,
                        message: 'ckkey不能为空',
                        trigger: 'blur'
                    }
                ],
                signature: [
                    {
                        required: true,
                        message: 'signature不能为空',
                        trigger: 'blur'
                    }
                ],
                username: [
                    {
                        required: true,
                        message: 'username不能为空',
                        trigger: 'blur'
                    }
                ],
            },
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadShortMsgFrom()
    },
    methods: {
        // tab切换
        handleClick(tab) {
            switch (tab.paneName) {
                // case '1':
                //     this.loadAliForm()
                //     break
                // case '2':
                //     this.loaddingForm()
                //     break
                // case '3':
                //     this.loadversionForm()
                //     break
                // case '4':
                //     this.loadZfbForm()
                //     break
                // case '5':
                //     this.loadZfbAppletForm()
                //     break
                // case '6':
                //     this.loadQqH5tForm()
                //     break
                // case '7':
                //     this.loadToutiaoH5Form()
                //     break
                // case '8':
                //     this.loadAppForm()
                //     break
                // case '9':
                //     this.loadAzExpressForm()
                //     break
                // case '10':
                //     this.loadRenlianFrom()
                //     break
                case '1':
                    this.loadShortMsgFrom()
                    break

            }
        },
        //短信配置
        loadShortMsgFrom() {
            this.loading = true;
            request.get('/system/zrhy/sms/list').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.ShortMsgFrom = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 短信编辑
        saveShortMsgFrom() {
            this.loading = true;
            request.post('/system/zrhy/sms/edit', this.ShortMsgFrom).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.ShortMsgFrom = ret.data;
                    this.$message.success('编辑成功');
                    this.loadShortMsgFrom()
                    this.$refs.ShortMsgFrom.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }

            });
        },

    }
}
</script>
<style scoped>
.el-form-item--small.el-form-item {
    margin-top: 30px;
}

.el-input--small {
    margin-left: 10px;
}

.el-button {
    /* margin-left: 210px; */
    margin: 60px 0 80px 0;
}

.containet {
    background: #ffffff;
}

.form-box {
    width: 100%;
}

.form-input >>> .el-input__inner {
    width: 900px;
    border-radius: 4px;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}

/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}
</style>